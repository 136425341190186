import axios from "axios";
export  function fixFloat(f){
    return Math.round(f * 100) / 100;
}

export async function downloadAs(url, fileName, { onDownloadStart, onDownloadEnd }) {
  onDownloadStart && onDownloadStart();
  try {
    const response = await axios({
      url,
      method: 'GET',
      responseType: 'blob',
    });

    const blob = new Blob([response.data]);
    const urlDownload = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = urlDownload;
    link.setAttribute("download", fileName);
    link.click();

    window.URL.revokeObjectURL(urlDownload);
    onDownloadEnd && onDownloadEnd();
  } catch (error) {
    console.error('Download failed:', error);
    onDownloadEnd && onDownloadEnd(error);
  }


}

export function handleLazyLoadError () {
  alert("Network issues detected. Reloading the page...");
  window.location.reload();
};

export const sleep = m => new Promise(r => setTimeout(r, m))